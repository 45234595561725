<link *ngIf="useBrowserLocale" rel="resource" type="application/l10n"
  [attr.xhref]="localeFolderPath + '/locale.properties'" origin="ngx-extended-pdf-viewer" />
<pdf-dark-theme *ngIf="theme === 'dark'"></pdf-dark-theme>
<pdf-light-theme *ngIf="theme === 'light'"></pdf-light-theme>
<pdf-acroform-dark-theme *ngIf="formTheme === 'dark'"></pdf-acroform-dark-theme>
<pdf-acroform-default-theme *ngIf="formTheme === 'light'"></pdf-acroform-default-theme>

<pdf-dynamic-css [zoom]="mobileFriendlyZoomScale" [width]="toolbarWidthInPixels"></pdf-dynamic-css>
<ng-content *ngTemplateOutlet="customPdfViewer ? customPdfViewer : defaultPdfViewer"></ng-content>

<ng-template #defaultPdfViewer>
  <div class="zoom" [style.height]="minHeight ? minHeight : height" #root>
    <div class="html">
      <div class="body" [style.backgroundColor]="backgroundColor">
        <div id="outerContainer" (window:resize)="onResize()">
          <div class="free-floating-bar" *ngIf="showFreeFloatingBar">
            <ng-content *ngTemplateOutlet="customFreeFloatingBar ? customFreeFloatingBar : defaultFreeFloatingBar">
            </ng-content>
          </div>
          <pdf-sidebar #pdfsidebar [sidebarVisible]="sidebarVisible || false" [showSidebarButton]="showSidebarButton"
            [customSidebar]="customSidebar" [customThumbnail]="customThumbnail"
            (thumbnailDrawn)="thumbnailDrawn.emit($event)" [mobileFriendlyZoomScale]="mobileFriendlyZoomScale"
            [sidebarPositionTop]="sidebarPositionTop">
          </pdf-sidebar>
          <div id="mainContainer" [class.toolbar-hidden]="!primaryMenuVisible">
            <pdf-dummy-components></pdf-dummy-components>

            <pdf-toolbar [customToolbar]="customToolbar" [mobileFriendlyZoomScale]="mobileFriendlyZoomScale"
              [primaryMenuVisible]="primaryMenuVisible" [showBookmarkButton]="showBookmarkButton"
              [showDownloadButton]="showDownloadButton" [showEditor]="showEditor" [showFindButton]="showFindButton"
              [showHandToolButton]="showHandToolButton" [showOpenFileButton]="showOpenFileButton"
              [showPrintButton]="showPrintButton && enablePrint" [showPagingButtons]="showPagingButtons"
              [showPresentationModeButton]="showPresentationModeButton" [showRotateButton]="showRotateButton"
              [showSecondaryToolbarButton]="showSecondaryToolbarButton && !hideKebabMenuForSecondaryToolbar"
              [showSidebarButton]="showSidebarButton" [showZoomButtons]="showZoomButtons" [textLayer]="textLayer"
              [toolbarMarginTop]="toolbarMarginTop" [toolbarWidth]="toolbarWidth"
              (onToolbarLoaded)="onToolbarLoaded($event)" [zoomLevels]="zoomLevels"></pdf-toolbar>

            <div class="editorParamsToolbar hidden doorHangerRight" id="editorFreeTextParamsToolbar">
              <div class="editorParamsToolbarContainer">
                <div class="editorParamsSetter">
                  <label for="editorFreeTextColor" class="editorParamsLabel"
                    data-l10n-id="editor_free_text_font_color">Font Color</label>
                  <input type="color" id="editorFreeTextColor" class="editorParamsColor" tabindex="100">
                </div>
                <div class="editorParamsSetter">
                  <label for="editorFreeTextFontSize" class="editorParamsLabel"
                    data-l10n-id="editor_free_text_font_size">Font Size</label>
                  <input type="range" id="editorFreeTextFontSize" class="editorParamsSlider" value="10" min="5"
                    max="100" step="1" tabindex="101">
                </div>
              </div>
            </div>

            <div class="editorParamsToolbar hidden doorHangerRight" id="editorInkParamsToolbar">
              <div class="editorParamsToolbarContainer">
                <div class="editorParamsSetter">
                  <label for="editorInkColor" class="editorParamsLabel" data-l10n-id="editor_ink_color">Color</label>
                  <input type="color" id="editorInkColor" class="editorParamsColor" tabindex="102">
                </div>
                <div class="editorParamsSetter">
                  <label for="editorInkThickness" class="editorParamsLabel"
                    data-l10n-id="editor_ink_thickness">Thickness</label>
                  <input type="range" id="editorInkThickness" class="editorParamsSlider" value="1" min="1" max="20"
                    step="1" tabindex="103">
                </div>
                <div class="editorParamsSetter">
                  <label for="editorInkOpacity" class="editorParamsLabel"
                    data-l10n-id="editor_ink_opacity">Opacity</label>
                  <input type="range" id="editorInkOpacity" class="editorParamsSlider" value="100" min="1" max="100"
                    step="1" tabindex="104">
                </div>
              </div>
            </div>


            <pdf-secondary-toolbar #pdfSecondaryToolbarComponent [customSecondaryToolbar]="customSecondaryToolbar"
              [secondaryToolbarTop]="secondaryToolbarTop" [mobileFriendlyZoomScale]="mobileFriendlyZoomScale"
              [showPresentationModeButton]="showPresentationModeButton" [showOpenFileButton]="showOpenFileButton"
              [showPrintButton]="showPrintButton && enablePrint" [showDownloadButton]="showDownloadButton"
              [showBookmarkButton]="showBookmarkButton" [showPagingButtons]="showPagingButtons"
              [showRotateButton]="showRotateButton" [showHandToolButton]="showHandToolButton"
              [showScrollingButton]="showScrollingButton" [showSpreadButton]="showSpreadButton"
              [showPropertiesButton]="showPropertiesButton" (spreadChange)="onSpreadChange($event)"
              (secondaryMenuIsEmpty)="onSecondaryMenuIsEmpty($event)">
            </pdf-secondary-toolbar>

            <pdf-findbar [findbarLeft]="findbarLeft" [findbarTop]="findbarTop"
              [mobileFriendlyZoomScale]="mobileFriendlyZoomScale" [showFindButton]="showFindButton || false"
              [customFindbarInputArea]="customFindbarInputArea" [customFindbarButtons]="customFindbarButtons"
              [showFindCurrentPageOnly]="showFindCurrentPageOnly" [showFindEntirePhrase]="showFindEntirePhrase"
              [showFindEntireWord]="showFindEntireWord" [showFindFuzzySearch]="showFindFuzzySearch"
              [showFindHighlightAll]="showFindHighlightAll" [showFindIgnoreAccents]="showFindIgnoreAccents"
              [showFindMatchCase]="showFindMatchCase" [showFindMessages]="showFindMessages"
              [showFindPageRange]="showFindPageRange" [showFindResultsCount]="showFindResultsCount">
            </pdf-findbar>

            <pdf-context-menu></pdf-context-menu>

            <div id="viewerContainer" [style.top]="viewerPositionTop" [style.backgroundColor]="backgroundColor"
              tabindex="0">
              <div class="unverified-signature-warning" *ngIf="hasSignature && showUnverifiedSignatures">
                {{
                'unverified_signature_warning'
                | translate
                : "This PDF file contains a digital signature. The PDF viewer can't verify if the signature is valid.
                Please download the file and open it in Acrobat Reader to verify the signature is valid."
                | async
                }}
              </div>
              <div class="modified-background-warning" *ngIf="pdfBackground">
                {{
                'modified_background_warning'
                | translate: 'This PDF is rendered with a custom background. It does not look the way its author
                intended it to look.'
                | async
                }}
              </div>
              <div id="viewer" class="pdfViewer" (dblclick)="zoomToPageWidth($event)"></div>
            </div>
            <pdf-error-message></pdf-error-message>
          </div>
          <!-- mainContainer -->

          <div id="dialogContainer">
            <pdf-password-dialog></pdf-password-dialog>
            <pdf-document-properties-dialog></pdf-document-properties-dialog>
            <pdf-prepare-printing-dialog></pdf-prepare-printing-dialog>
          </div>
          <!-- dialogContainer -->
        </div>
        <!-- outerContainer -->
        <input type="file" id="fileInput" class="hidden" />
        <div id="printContainer"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultFreeFloatingBar> </ng-template>
