<div class="toolbar" [class.invisible]="!primaryMenuVisible"
  [style.transform]="'scale(' + mobileFriendlyZoomScale + ')'" [style.transformOrigin]="'left center'"
  [style.width]="toolbarWidth" [style.marginTop]="toolbarMarginTop">
  <div id="toolbarContainer">
    <ng-content *ngTemplateOutlet="customToolbar ? customToolbar : defaultToolbar"></ng-content>
    <div id="loadingBar">
      <div class="progress">
        <div class="glimmer"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultToolbar>
  <div id="toolbarViewer">
    <div id="toolbarViewerLeft">
      <pdf-toggle-sidebar [showSidebarButton]="showSidebarButton"></pdf-toggle-sidebar>
      <pdf-find-button [showFindButton]="showFindButton" [textLayer]="textLayer"></pdf-find-button>
      <pdf-paging-area [showPagingButtons]="showPagingButtons"></pdf-paging-area>
    </div>
    <pdf-zoom-toolbar [showZoomButtons]="showZoomButtons" [zoomLevels]="zoomLevels"></pdf-zoom-toolbar>
    <div id="toolbarViewerRight">


      <pdf-hand-tool [showHandToolButton]="showHandToolButton"></pdf-hand-tool>
      <pdf-select-tool [showSelectToolButton]="showHandToolButton"></pdf-select-tool>
      <pdf-rotate-page [showRotateButton]="showRotateButton" [clockwise]="true" [counterClockwise]="false">
      </pdf-rotate-page>
      <pdf-rotate-page [showRotateButton]="showRotateButton" [clockwise]="false" [counterClockwise]="true">
      </pdf-rotate-page>
      <pdf-presentation-mode [showPresentationModeButton]="showPresentationModeButton"></pdf-presentation-mode>
      <pdf-open-file [showOpenFileButton]="showOpenFileButton"></pdf-open-file>
      <pdf-print [showPrintButton]="showPrintButton"></pdf-print>
      <pdf-download [showDownloadButton]="showDownloadButton"></pdf-download>
      <pdf-bookmark [showBookmarkButton]="showBookmarkButton"></pdf-bookmark>

      <div id="editorModeButtons" class="splitToolbarButton toggled" role="radiogroup" *ngIf="showEditor">
        <button id="editorFreeText" class="toolbarButton" disabled="disabled" title="Text" role="radio"
          aria-checked="false" tabindex="34" data-l10n-id="editor_free_text2">
          <span data-l10n-id="editor_free_text2_label">Text</span>
          <svg style="width:20px;height:20px" viewBox="0 0 24 24">
            <path fill="currentColor"
              d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z" />
          </svg>
        </button>
        <button id="editorInk" class="toolbarButton" disabled="disabled" title="Draw" role="radio" aria-checked="false"
          tabindex="35" data-l10n-id="editor_ink2">
          <span data-l10n-id="editor_ink2_label">Draw</span>
          <svg style="width:20px;height:20px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
            xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 16 16">
            <g>
              <g transform="scale(0.03125)">
                <path
                  d="m455.1,137.9l-32.4,32.4-81-81.1 32.4-32.4c6.6-6.6 18.1-6.6 24.7,0l56.3,56.4c6.8,6.8 6.8,17.9 0,24.7zm-270.7,271l-81-81.1 209.4-209.7 81,81.1-209.4,209.7zm-99.7-42l60.6,60.7-84.4,23.8 23.8-84.5zm399.3-282.6l-56.3-56.4c-11-11-50.7-31.8-82.4,0l-285.3,285.5c-2.5,2.5-4.3,5.5-5.2,8.9l-43,153.1c-2,7.1 0.1,14.7 5.2,20 5.2,5.3 15.6,6.2 20,5.2l153-43.1c3.4-0.9 6.4-2.7 8.9-5.2l285.1-285.5c22.7-22.7 22.7-59.7 0-82.5z" />
              </g>
            </g>
          </svg>
        </button>
      </div>

      <div id="editorModeSeparator" class="verticalToolbarSeparator"></div>

      <pdf-toggle-secondary-toolbar [showSecondaryToolbarButton]="showSecondaryToolbarButton">
      </pdf-toggle-secondary-toolbar>

    </div>
  </div>
</ng-template>
